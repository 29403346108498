/* unvisited link */
.CellLinkRenderer a:link {
    color: rgb(24, 29, 31);
    text-decoration: none;
}

/* visited link */
.CellLinkRenderer a:visited {
    color: rgb(24, 29, 31);
    text-decoration: none;
}

/* mouse over link */
.CellLinkRenderer a:hover {
    color: blue;
    text-decoration: underline;
}

/* selected link */
.CellLinkRenderer a:active {
    color: rgb(24, 29, 31);
    text-decoration: none;
}
